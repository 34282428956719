import {exportUserSession, globalFetchOptions} from "~/utils/helpers";

const fetchOptions = () => {
  const config = useRuntimeConfig();
  // check cookie.value if value does not exist then take the value from localStorage
  const cookie = exportUserSession();
  return {
    baseURL: config.public.adminToolsApiBase as string,
    headers: {
      Authorization: `Bearer ${cookie.value}`,
    },
  };
};

export class IntegrationModel {
  static checkIntegrationEnabled = () => {
    const config = useRuntimeConfig();
    return $fetch<{ success: boolean; data: boolean }>(`/faqs/modules/SAHelp/enabled`, {
      ...fetchOptions(),
      method: 'get',
    });
  };
}
